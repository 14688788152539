import "./App.css";
import HomePage from "./Components/HomePage.js";
import Updates from "./Components/Updates.js";
import Certificates from "./Components/Certificates.js";
import { Routes, Route } from "react-router-dom";
import Drums from "./Components/Drums.js";
import Contact from "./Components/Contact.js";
import Earlier from "./Components/Earlier.js";
import ScrollTop from "./Components/ScrollTop.js";

import UpdatePage from "./Components/UpdatePage.js"; //import UpdatePage0 from "./Components/UpdatePages/UpdatePage0.js";

import UpdatePageE1 from "./Components/UpdatePages/UpdatePageE1.js";
import UpdatePageE3 from "./Components/UpdatePages/UpdatePageE3.js";
import UpdatePageE4 from "./Components/UpdatePages/UpdatePageE4.js";

function App() {
  return (
    <div className="App">
    <ScrollTop/>
      <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Updates" element={<Updates />} />
          <Route path="/Certificates" element={<Certificates />} />
          <Route path="/Drums" element={<Drums />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Earlier" element={<Earlier />} />

          <Route path="/pages/:idRouter" element={<UpdatePage />} />

          <Route path="/E1" element={<UpdatePageE1 />} />
          <Route path="/E3" element={<UpdatePageE3 />} />
          <Route path="/E4" element={<UpdatePageE4 />} />

          <Route path="*" component={<HomePage />} />
      </Routes>
    </div>
  );
}

export default App;
