import "../App.css";
import Certificates from "../JSON/Certificates.json";
import NavBar from "./NavBar";

import Footer from "./Footer";

import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { fadeInLeft } from "react-animations";

const FadeInLeft = styled.div`
  animation: ${({ animationTime }) => `${animationTime}s`}
    ${keyframes`${fadeInLeft}`};
`;

function App() {
  return (
    <div className="App">
      <NavBar />
      <main>
        <h1 style={{marginLeft: "20px"}} className="HeadTitle">
          Contact me:
        </h1>
        <FadeInLeft animationTime={1}>
          <section className="contact">
            <form name="Form">
              <label>Name</label>
              <input type="text" placeholder="Enter your name" id="name" />
              <br />
              <label>Email</label>
              <input type="text" placeholder="Enter your email" id="email" />
              <br />
              <label>Subject</label>
              <input
                type="text"
                placeholder="Enter your subject"
                id="subject"
              />
              <br />
              <label>Content</label>
              <textarea placeholder="Some text..." id="content"></textarea>
              <br />
              <button type="submit" value="Submit" disabled>
                Send message
              </button>
            </form>
          </section>
        </FadeInLeft>

        <h1 style={{marginLeft: "20px"}} id="SecondTitle">
          or just send an email to this adress: <i>kh.zgueb@gmail.com</i>
        </h1>
      </main>
      <Footer />
    </div>
  );
}

export default App;
