import "../App.css";
import Certificates from "../JSON/Certificates.json";
import NavBar from "./NavBar";

import Footer from "./Footer";

import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeInLeft } from 'react-animations';

const FadeInLeft = styled.div`animation: ${({ animationTime }) => `${animationTime}s`} ${keyframes`${fadeInLeft}`}`;

function App() {
  return (
    <div className="App">
    
    <NavBar/>
    <main style={{textAlign: "center", alignItems: "center"}} className="DrumsArticles">
    {[...Certificates[2].data].reverse().map((element, i) => {
          return (<FadeInLeft animationTime={1+(i*0.2)}><article><a href={(element.Link != "")?(element.Link):"Certificates"}><img src={".\\"+element.Certificate} /></a></article></FadeInLeft>);
        })}

  </main>
    <Footer />
    </div>
  );
}

export default App;