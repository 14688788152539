import "../App.css";
import Drums from "../JSON/Drums.json";
import NavBar from "./NavBar";

import Footer from "./Footer";

import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeInLeft } from 'react-animations';

const FadeInLeft = styled.div`animation: ${({ animationTime }) => `${animationTime}s`} ${keyframes`${fadeInLeft}`}`;

function App() {
  return (
    <div className="App">
    
    <NavBar/>
    <main style={{textAlign: "center", alignItems: "center"}} className="DrumsArticles">
    {[...Drums[2].data].reverse().map((element, i) => {
          return (<FadeInLeft animationTime={1+(i*0.2)}><article>
            {(element.Image != "")?(<img src={element.Image} />):((element.Orientation == 1)?(<video controls style={{width:"40%"}}><source src={element.Video} type="video/mp4" />Your browser does not support the video tag.</video>):(<video controls><source src={element.Video} type="video/mp4" />Your browser does not support the video tag.</video>))}
            
            <h1>{element.Name}</h1>
            </article></FadeInLeft>);
        })}

  </main>
    <Footer />
    </div>
  );
}

export default App;